<!-- eslint-disable vue/html-self-closing -->
<template>
  <!-- BEGIN: Content-->
  <div class="app-content pt-0">
    <div class="content-overlay"></div>

    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0"></h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body content-body-send-ok mt-5 pt-2">
        <div class="row">
          <div class="col-12 text-center mt-2">
            <h1 class="display-3 text-success">
              <i data-feather="check-circle"></i><br />Candidacy successfully submitted
            </h1>
            <h2>Hello {{ user.name }}</h2>
            <p>
              <strong>You have successfully submitted your candidacy to the {{ convo.title }}.</strong>
            </p>
            <p>
              Remember that you can access your candidacy to modify your data
              until <strong>{{ convo.end_date_formatted }}</strong> at {{ convo.end_hour_formatted }} p.m. (after this date you will only
              be able to consult it).
            </p>
            <p>
              <a
                class="btn btn-success"
                @click="returnToConvo"
              >Return to your candidacy</a>
            </p>
            <img
              :src="require('@/assets/images/send-call-success.svg')"
              alt="success"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'

// import assets styles
require('@/assets/scss/public/bootstrap.scss')
require('@/assets/scss/public/bootstrap-extended.scss')
require('@/assets/scss/public/components.scss')
require('@/assets/scss/public/style.scss')

export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      user: 'auth/user',
    }),
  },
  methods: {
    returnToConvo() {
      if (this.$route.name.includes('senior-call')) {
        this.$router.push({ name: 'front.senior-call' })
      } else if (this.$route.name.includes('academy-call')) {
        this.$router.push({ name: 'front.academy-call' })
      }
    },
  },
}
</script>
